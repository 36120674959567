const projects = [
  
  {
    id: "noteit",
    title: "Note-it",
    slides: [
      {
        url: "https://res.cloudinary.com/dhqh98spd/image/upload/v1720697587/noteit_light_k2wpls.webp",
        title: "main page, mode jour",
      },
      {
        url: "https://res.cloudinary.com/dhqh98spd/image/upload/v1720697587/noteit_dark_qtga9f.webp",
        title: "main page, mode nuit",
      },
    ],
    content: `
      <p>Application de prise de note légère dans le style de Google Keep</p>
        <div>
          <a href="https://note-it-sz.netlify.app" class="download-file " target="_blank" rel="noopener noreferrer">
        Démo 
          </a>
        </div>
        </br>
      <span>Fonctionnalités Principales</span>
      <ul class="list">
        <li>Recherche des notes.</li>
        <li>Création, modification et suppression de notes.</li>
        <li>Archivage des notes.</li>
        
      </ul>
      <span>Technologies Utilisées</span>
      <ul class="list">
        <li><span class="bolder">Typescript</span> via le framework <span class="bolder">ReactJS </span></li>
        <li><span class="bolder">CSS</span></li>
        <li><span class="bolder">localstorage</span> pour conserver les données.</li>
        <li>Déployé sur <span class="bolder">netlify</span>.</li>
      </ul><div>
          <a href="https://github.com/souleimane-z/taskmanager" class="download-file " target="_blank" rel="noopener noreferrer">
Code source 
          </a>
        </div>
    `,
    githubLink: "https://github.com/souleimane-z/taskmanager",
    techs: [
      "https://res.cloudinary.com/dhqh98spd/image/upload/v1718881731/typescript_ki78sv.svg",
      "https://res.cloudinary.com/dhqh98spd/image/upload/v1718881731/react_ltwhrj.svg",
      "https://res.cloudinary.com/dhqh98spd/image/upload/v1718881731/javascript_sdrddu.svg",
      "https://res.cloudinary.com/dhqh98spd/image/upload/v1718881735/css3_k38wzt.svg",
      "https://res.cloudinary.com/dhqh98spd/image/upload/v1718958810/netlify_aelfck.svg",
    ],
  },
  {
    id: "prepquest",
    title: "Prep-Quest",
    slides: [
      {
        url: "https://res.cloudinary.com/dhqh98spd/image/upload/v1719236945/MAIN_elwyfh.webp",
        title: "Page principale",
      },
      {
        url: "https://res.cloudinary.com/dhqh98spd/image/upload/v1719236954/Signup-ipadPro_ik59eq.webp",
        title: "Page d'inscription sur iPad Pro",
      },
      {
        url: "https://res.cloudinary.com/dhqh98spd/image/upload/v1719236950/Login-ipadPro_pvmcwm.webp",
        title: "Page de connexion sur iPad Pro",
      },
      {
        url: "https://res.cloudinary.com/dhqh98spd/image/upload/v1719236946/main2-iphone_uylroo.webp",
        title: "Vue principale sur iPhone",
      },
      {
        url: "https://res.cloudinary.com/dhqh98spd/image/upload/v1719236945/main1-iphone_za1pfa.webp",
        title: "Vue secondaire sur iPhone",
      },
    ],
    content: `
    <p>Prep-Quest est une application web conçue pour aider les utilisateurs à préparer efficacement leurs entretiens d'embauche. Développée avec <span class="bolder">JavaScript</span>, <span class="bolder">Node.js</span>, et <span class="bolder">MongoDB</span>, cette application offre une plateforme interactive pour gérer et organiser des questions d'entretien.</p>
    
    <h3>Caractéristiques principales :</h3>
    <ul>
        <li>Interface utilisateur intuitive et responsive, adaptée à différents appareils</li>
        <li>Système d'authentification sécurisé avec <span class="bolder">JWT</span> (JSON Web Tokens)</li>
        <li>Création et gestion de catégories de questions personnalisées</li>
        <li>Ajout, modification et suppression de questions d'entretien</li>
        <li>Fonctionnalité de marquage des questions comme complétées</li>
        <li>Backend robuste utilisant <span class="bolder">Node.js</span> et <span class="bolder">Express.js</span></li>
        <li>Base de données <span class="bolder">MongoDB</span> pour un stockage efficace des données</li>
        <li><span class="bolder">API RESTful</span> pour une communication fluide entre le frontend et le backend</li>
    </ul>
    
    <p>Cette application démontre une mise en œuvre complète d'une app <span class="italic-semiBold">Fullstack</span>, offrant une expérience utilisateur fluide et une architecture backend solide. Elle illustre également l'utilisation de bonnes pratiques en matière de sécurité web et de gestion des données utilisateur.</p>

    `,
    githubLink: "https://github.com/souleimane-z/PrepQuest",
    techs: [
      "https://res.cloudinary.com/dhqh98spd/image/upload/v1718881731/javascript_sdrddu.svg",
      "https://res.cloudinary.com/dhqh98spd/image/upload/v1718881734/mongodb_x5llwm.svg",
      "https://res.cloudinary.com/dhqh98spd/image/upload/v1718881735/nodeJS_mgeyrh.svg",
      "https://res.cloudinary.com/dhqh98spd/image/upload/v1718881736/html5_gvbsau.svg",
      "https://res.cloudinary.com/dhqh98spd/image/upload/v1718881735/css3_k38wzt.svg",
      "https://res.cloudinary.com/dhqh98spd/image/upload/v1718881733/api_ue2yxg.svg",
      "https://res.cloudinary.com/dhqh98spd/image/upload/v1719237312/jwtio-json-web-token_mope1u.svg",
    ],
  },
  {
    id: "passwordgen",
    title: "PasswordGen.py",
    slides: [
      {
        url: "https://res.cloudinary.com/dhqh98spd/image/upload/v1719066048/CQP8cFo_puipwm.webp",
        title: "main page, passwordgen",
      },
    ],
    content: `
    <p>Ce projet est une application web élégante et fonctionnelle qui génère des mots de passe aléatoires et sécurisés. Développée avec <span class="bolder">Python</span> et <span class="bolder">Flask </span> pour le backend, et <span class="bolder">HTML/CSS</span> pour le frontend.</p>
    
    <h3>Caractéristiques principales :</h3>
    <ul>
        <li>Interface utilisateur intuitive et responsive</li>
        <li>Génération de mots de passe personnalisables en longueur</li>
        <li>Utilisation de caractères alphanumériques et spéciaux pour une sécurité accrue</li>
        <li>Backend robuste utilisant <span class="bolder">Python</span> et <span class="bolder">Flask </span></li>
        <li>Frontend élégant avec un design moderne en <span class="bolder">HTML</span> et <span class="bolder">CSS </span></li>
        <li>Site mis en ligne sur <span class="italic-semiBold">pythonanywhere</span></li>
    </ul>
    
    <div>
        <a href="http://souzzfr.pythonanywhere.com/" class="download-file " target="_blank" rel="noopener noreferrer">Voir une démo</a>
        <a href="https://github.com/souleimane-z/passwordgen.py" class="download-file " target="_blank" rel="noopener noreferrer">Code source</a>
    </div>
    `,
    githubLink: "https://github.com/souleimane-z/weather-today",
    techs: [
      "https://res.cloudinary.com/dhqh98spd/image/upload/v1719065285/flask_alpgzv.svg",
      "https://res.cloudinary.com/dhqh98spd/image/upload/v1718881736/python_aonlz6.svg",
      "https://res.cloudinary.com/dhqh98spd/image/upload/v1718881731/javascript_sdrddu.svg",
      "https://res.cloudinary.com/dhqh98spd/image/upload/v1718881736/html5_gvbsau.svg",
      "https://res.cloudinary.com/dhqh98spd/image/upload/v1718881735/css3_k38wzt.svg",
    ],
  },
  {
    id: "weathertoday",
    title: "Weather Today",
    slides: [
      {
        url: "https://res.cloudinary.com/dhqh98spd/image/upload/v1718365650/weather-today_hwyb1v.webp",
        title: "main page, weather shown",
      },
    ],
    content: `
      <p>Application de météo en temps réel.</p>
        <div>
          <a href="https://souleimane-z.github.io/weather-today/" class="download-file " target="_blank" rel="noopener noreferrer">
Démo GitPages
          </a>
        </div>
        </br>
      <span>Fonctionnalités Principales</span>
      <ul class="list">
        <li>Recherche par Ville : Entrez le nom d'une ville pour obtenir instantanément les conditions météorologiques actuelles.</li>
        <li>Détails Météorologiques : Affichage des informations essentielles telles que la température, les conditions météorologiques, l'humidité et la vitesse du vent.</li>
        <li>Design Responsive : Adapté à tous les types d'écrans, des ordinateurs de bureau aux smartphones.</li>
      </ul>
      <span>Technologies Utilisées</span>
      <ul class="list">
        <li><span class="bolder">ReactJS </span></li>
        <li><span class="bolder">Axios, Express.js et Node.js </span></li>
        <li><span class="bolder">Vercel </span>: Pour heberger le backend.</li>
        <li>Déployé sur <span class="bolder">GitHub Pages </span>.</li>
      </ul>
      <p>Pour protéger la clé API utilisée pour accéder aux données météorologiques, une <span class="italic-semiBold">API Proxy</span> a été créée. C'est une API REST, et une API Proxy a été mise en place. Cette API Proxy interagit avec l'<span class="italic-semiBold">API OpenWeatherMap</span> pour l'extraction des données. C'est une API OpenWeatherMap, garantissant que la <span class="italic-semiBold">clé API</span> n'est jamais exposée dans le code front-end public.</p>

    `,
    githubLink: "https://github.com/souleimane-z/weather-today",
    techs: [
      "https://res.cloudinary.com/dhqh98spd/image/upload/v1718881731/react_ltwhrj.svg",
      "https://res.cloudinary.com/dhqh98spd/image/upload/v1718958811/vercel_kegfnm.svg",
      "https://res.cloudinary.com/dhqh98spd/image/upload/v1718881731/javascript_sdrddu.svg",
      "https://res.cloudinary.com/dhqh98spd/image/upload/v1718881735/nodeJS_mgeyrh.svg",
      "https://res.cloudinary.com/dhqh98spd/image/upload/v1718881736/express_s7lr4n.svg",
    ],
  },
  {
    id: "songinsight",
    title: "SongInsight Python",
    slides: [
      {
        url: "https://res.cloudinary.com/dhqh98spd/image/upload/v1718196170/songinsight_mainframe_wc89wr.png",
        title: "main program",
      },
      {
        url: "https://res.cloudinary.com/dhqh98spd/image/upload/v1718196168/songinsight_exportFile_aq7fib.png",
        title: "Export File",
      },
    ],
    content: `
        <p>SongInsight est une application <span class="bolder">Python</span> entièrement
        fonctionnelle pour extraire et afficher les métadonnées des fichiers audio.</p>
        <ul class="list">
          <li>Prend en charge plusieurs formats audio : MP3, FLAC, ALAC, M4A, MP4, OGG, WAV et WV.</li>
          <li>Affiche toutes les métadonnées disponibles dans un tableau trié avec les clés des métadonnées en gras.</li>
          <li>Affiche la durée du fichier audio en heures, minutes et secondes (h:min:s).</li>
          <li>Permet d'exporter les métadonnées dans un fichier texte nommé d'après le fichier audio.</li>
        </ul>
        <p>Développé en utilisant <span class="bolder">Python</span> avec la bibliothèque <span class="italic-semiBold">mutagen</span> pour l'extraction des métadonnées, et <span class="italic-semiBold">Tkinter</span> pour l'interface graphique.</p>
      `,
    githubLink: "https://github.com/souleimane-z/SongInsight",
    techs: [
      "https://res.cloudinary.com/dhqh98spd/image/upload/v1718881736/python_aonlz6.svg",
      "https://res.cloudinary.com/dhqh98spd/image/upload/v1718981845/mutagen_dic1c1.svg",
      "https://res.cloudinary.com/dhqh98spd/image/upload/v1718982307/tkinter_qqtnd9.png",
    ],
  },
  {
    id: "Shopping_List",
    title: "Shopping List",
    slides: [
      {
        url: "https://res.cloudinary.com/dhqh98spd/image/upload/v1723916821/og-image-gigapixel_oh3bsd.png",
        title: "Homepage",
      },
      {
        url: "https://res.cloudinary.com/dhqh98spd/image/upload/v1723916824/DNdPYTm_qdbnfb.png",
        title: "List",
      },
    ],
    content: `
      <p>Application de liste d'achat.</p>
        <div>
          <a href="https://shopping-list-alpha-three.vercel.app/" class="download-file " target="_blank" rel="noopener noreferrer">
Démo
          </a>
        </div>
        </br>
      <span>Fonctionnalités Principales</span>
      <ul class="list">
        <li>Ajout d'un produit à la liste. Avec son nom, image, prix et URL vers le site marchand.</li>
        <li>Possibilité d'exporter la liste sous format PDF.</li>
        <li>Enregistrement de la liste dans le navigateur grâce au LocalStorage.</li>
    `,
    // githubLink: "https://github.com/souleimane-z/shopping_list",
    techs: [
      "https://res.cloudinary.com/dhqh98spd/image/upload/v1718881736/html5_gvbsau.svg",
      "https://res.cloudinary.com/dhqh98spd/image/upload/v1718881735/css3_k38wzt.svg",
    ],
  },
  {
    id: "portfolio",
    title: "Portfolio",
    slides: [
      {
        url: "https://res.cloudinary.com/dhqh98spd/image/upload/v1700124774/header_dkarni.png",
        title: "header",
      },
      {
        url: "https://res.cloudinary.com/dhqh98spd/image/upload/v1700124776/AboutMe_eng9cf.png",
        title: "aboutMe",
      },
      {
        url: "https://res.cloudinary.com/dhqh98spd/image/upload/v1700124778/projects_qzvmdk.png",
        title: "projects",
      },
      {
        url: "https://res.cloudinary.com/dhqh98spd/image/upload/v1718282775/contactMe1-gigapixel-standard_v2-2x_ul6tci.webp",
        title: "Send a mail from portfolio",
      },
      {
        url: "https://res.cloudinary.com/dhqh98spd/image/upload/v1718282768/contactMe3-gigapixel-standard_v2-2x_uaac5f.webp",
        title: "email received",
      },
    ],
    content: `
        <p>Ce site est principalement codé en <span class="bolder">JavaScript</span> grâce à l'utilisation de <span class="bolder">ReactJS</span> comme Framework, et ses Hooks tels que <span class="italic-semiBold">useState</span> & <span class="italic-semiBold">useEffect</span>. <span class="bolder">NodeJS</span> sert, ici, à envoyer les messages du formulaire de contact directement vers mon Gmail grâce à <span class="italic-semiBold">Nodemailer</span>.</p>
        <p>Le site est déployé sur Netifly, et j'utilise <a href="https://uptimerobot.com/" class="link-paragraph" target="_blank" rel="noopener noreferrer">Uptime Robot</a> pour monitorer l'application et empêcher qu'elle se mette en veille au bout de 30min pour un temps de chargement plus court.</p>
        <p>Le but de ce Portfolio était de créer un site "one-page" dans lequel on pouvait retrouver toutes mes informations essentielles sans qu'il ne soit trop lourd à la lecture.<br>Les images sont hébergées sur <span class="italic-semiBold">Cloudinary</span> pour encore une fois limiter le poids du site. Vous avez la possibilité de télécharger mon CV présenté de façon plus formelle. Dans lequel vous retrouverez d'autres manières de me contacter comme mon numéro de téléphone.</p>
      `,
    githubLink: "https://github.com/souleimane-z/Portfolio_React2022",
    techs: [
      "https://res.cloudinary.com/dhqh98spd/image/upload/v1718881731/react_ltwhrj.svg",
      "https://res.cloudinary.com/dhqh98spd/image/upload/v1718881735/nodeJS_mgeyrh.svg",
      "https://res.cloudinary.com/dhqh98spd/image/upload/v1718881731/javascript_sdrddu.svg",
      "https://res.cloudinary.com/dhqh98spd/image/upload/v1718958810/netlify_aelfck.svg",
      "https://res.cloudinary.com/dhqh98spd/image/upload/v1718881733/api_ue2yxg.svg",
      "https://res.cloudinary.com/dhqh98spd/image/upload/v1718881731/cloudflare_lrzi5i.svg",
      "https://res.cloudinary.com/dhqh98spd/image/upload/v1718881732/cloudinary_vepdwg.svg",
      "https://res.cloudinary.com/dhqh98spd/image/upload/v1718881733/uptimerobot_n434ik.svg",
    ],
  },
  {
    id: "groupomania",
    title: "Groupomania",
    slides: [
      {
        url: "https://res.cloudinary.com/dhqh98spd/image/upload/v1700124977/homepage_o213fi.png",
        title: "homepage",
      },
      {
        url: "https://res.cloudinary.com/dhqh98spd/image/upload/v1700124976/phone_pbidvt.jpg",
        title: "phone",
      },
      {
        url: "https://res.cloudinary.com/dhqh98spd/image/upload/v1700124976/tablet_zzuqex.jpg",
        title: "tablet",
      },
      {
        url: "https://res.cloudinary.com/dhqh98spd/image/upload/v1700124976/connexion_vly7of.png",
        title: "connexion",
      },
      {
        url: "https://res.cloudinary.com/dhqh98spd/image/upload/v1700124976/signup_z7gyp6.png",
        title: "inscription",
      },
      {
        url: "https://res.cloudinary.com/dhqh98spd/image/upload/v1700124977/post_ufsc35.png",
        title: "post",
      },
    ],
    content: `
        <p>Simulation d'un réseau social d'entreprise totalement fonctionnel.</p>
        <ul class="list">
          <li>On a la possibilité de créer un compte grâce à une adresse mail et un username qui sera unique. Les mots de passe sont hashé.</li>
          <li>Un compte administrateur qui pourra supprimer toutes les publications et commentaires qu'il-elle souhaite.</li>
          <li>Partager du contenu multimédia, et texte.</li>
          <li>Interagir en commentant les publications.</li>
        </ul>
        <p>Codé grâce au Framework <span class="bolder">ReactJS</span> et <span class="bolder">Tailwind CSS</span> pour le style, pour la partie backend une base de données <span class="bolder">MySQL</span> a été implémenté et intégré grâce à <span class="italic-semiBold">NodeJS</span> et <span class="italic-semiBold">Express</span>.</p>
      `,
    githubLink: "https://github.com/souleimane-z/SZ_P7_01032022",
    techs: [
      "https://res.cloudinary.com/dhqh98spd/image/upload/v1718881731/tailwind_yw3i1g.svg",
      "https://res.cloudinary.com/dhqh98spd/image/upload/v1718881731/react_ltwhrj.svg",
      "https://res.cloudinary.com/dhqh98spd/image/upload/v1718881734/mysql_r0tqsj.svg",
      "https://res.cloudinary.com/dhqh98spd/image/upload/v1718881735/nodeJS_mgeyrh.svg",
      "https://res.cloudinary.com/dhqh98spd/image/upload/v1718881736/express_s7lr4n.svg",
    ],
  },
  {
    id: "hot-Takes",
    title: "Hot-Takes",
    slides: [
      {
        url: "https://res.cloudinary.com/dhqh98spd/image/upload/v1700124989/Login_xrjzp5.jpg",
        title: "Login",
      },
      {
        url: "https://res.cloudinary.com/dhqh98spd/image/upload/v1700124990/homepage_ttupxp.jpg",
        title: "Homepage",
      },
      {
        url: "https://res.cloudinary.com/dhqh98spd/image/upload/v1700124990/sauce_zgar5c.jpg",
        title: "Sauce",
      },
    ],
    content: `
        <p>Construire une API sécurisée pour une application d'avis gastronomiques.<br>Scénario : la marque Piiquante souhaite créer un site dans lequel les utilisateurs peuvent noter leurs sauces piquantes favorites en les ajoutant eux-mêmes.<br>Je devais travailler sur le backend, et intégrer l'API. Les utilisateurs pouvaient ensuite ajouter une sauce avec une description et une photo puis les autres utilisateurs pourront ajouter un "like" ou un "dislike" à celle-ci.</p>
        <p>Le travail a été effectué depuis <span class="bolder">MongoDB</span> et <span class="bolder">NodeJS</span>. Pour la sécurité et l'authentification, j'ai utilisé <span class="italic-semiBold">Mongoose-unique-validator</span>, <span class="italic-semiBold">email-validator</span>, ou encore <span class="italic-semiBold">password-validator</span>.</p>
      `,
    githubLink: "https://github.com/souleimane-z/ZeggaiSouleimane_6_140122",
    techs: [
      "https://res.cloudinary.com/dhqh98spd/image/upload/v1718881735/nodeJS_mgeyrh.svg",
      "https://res.cloudinary.com/dhqh98spd/image/upload/v1718881733/api_ue2yxg.svg",
      "https://res.cloudinary.com/dhqh98spd/image/upload/v1718881734/mongodb_x5llwm.svg",
    ],
  },
  {
    id: "kanap",
    title: "Kanap",
    slides: [
      {
        url: "https://res.cloudinary.com/dhqh98spd/image/upload/v1700125031/home_imkiy8.jpg",
        title: "Home",
      },
      {
        url: "https://res.cloudinary.com/dhqh98spd/image/upload/v1700125029/product_c9ejye.jpg",
        title: "Product",
      },
      {
        url: "https://res.cloudinary.com/dhqh98spd/image/upload/v1700125030/recap_mc7zis.jpg",
        title: "Recap",
      },
      {
        url: "https://res.cloudinary.com/dhqh98spd/image/upload/v1700125030/infos-commande_pppiab.jpg",
        title: "infos commande",
      },
      {
        url: "https://res.cloudinary.com/dhqh98spd/image/upload/v1700125031/validation-commande_jqflh3.jpg",
        title: "Validation commande",
      },
    ],
    content: `
        <p>Site de vente de sofas en ligne. Comprenant:</p>
        <ul>
          <li>Une page d'accueil avec tous les canapés.</li>
          <li>Quand on clique sur un produit : on est redirigé vers une page détaillée avec la description de celui-ci.</li>
          <li>Choix de la couleur, et la quantité pour ajouter au panier.</li>
          <li>Page Panier avec : un récapitulatif des canapés ajoutés au panier.</li>
          <li>Formulaire à remplir pour commander</li>
          <li>Une fois le formulaire correctement rempli : redirection vers une page où l'on retrouve le numéro de commande</li>
        </ul>
        <p>Je devais travailler sur le backend. Avec <span class="bolder">Node JS</span>, mon rôle était d'unifier les travaux déjà réalisés par l’équipe en intégrant dynamiquement les éléments de l’API dans les différentes pages web avec <span class="bolder">JavaScript</span> et créer un <span class="italic-semiBold">plan de test d’acceptation</span>.</p>
        <div>
          <a href="https://app.box.com/s/5yfv1nwrbxmrxomuyqils4ek1h2hxqgj" target="_blank" rel="noopener noreferrer" class="download-file">
Plan de test d’acceptation
          </a>
        </div>
      `,
    githubLink: "https://github.com/souleimane-z/ZeggaiSouleimane_5_11112021",
    techs: [
      "https://res.cloudinary.com/dhqh98spd/image/upload/v1718881735/nodeJS_mgeyrh.svg",
      "https://res.cloudinary.com/dhqh98spd/image/upload/v1718881731/javascript_sdrddu.svg",
      "https://res.cloudinary.com/dhqh98spd/image/upload/v1718983533/excel-svgrepo-com_nkli94.svg",
    ],
  },
  {
    id: "la-Chouette-Agence",
    title: "La chouette agence",
    slides: [
      {
        url: "https://res.cloudinary.com/dhqh98spd/image/upload/v1700125048/un_fxjuoy.jpg",
        title: "un",
      },
      {
        url: "https://res.cloudinary.com/dhqh98spd/image/upload/v1700125049/deux_qbg0v0.jpg",
        title: "deux",
      },
      {
        url: "https://res.cloudinary.com/dhqh98spd/image/upload/v1700125048/trois_ucnfo7.jpg",
        title: "trois",
      },
      {
        url: "https://res.cloudinary.com/dhqh98spd/image/upload/v1700125046/quatre_vsgmhe.jpg",
        title: "quatre",
      },
    ],
    content: `
        <p><i>La Chouette Agence</i> est une agence de webdesign qui aimerait augmenter son trafic et l'accessibilité de son site. Pour ce faire, je devais trouver tous les axes d'amélioration du site web d'un point de vue de <span class="bolder">Réfférencement (SEO)</span> mais aussi <span class="bolder">l'Accessibilité (WCAG)</span>.</p>
        <div>
          <a href="https://app.box.com/s/4wizdpblw9u3deq1xj7wojpwbpisruhv" target="_blank" rel="noopener noreferrer" class="download-file">
Compte-rendu détaillé des améliorations faites
          </a>
        </div>
        <div>
          <a href="https://docs.google.com/spreadsheets/d/e/2PACX-1vR7nQ0A8WLkZodGnAhsTkKwUR1NS6lVJL97C346j8zWJ2VtYDfsZaG6hxXpCRjzyTmXia6uMWHgODu-/pubhtml?gid=981201691&single=true" class="download-file" target="_blank" rel="noopener noreferrer">
Tableur : Analyse des problèmes rencontrés
          </a>
        </div>
        <div>
          <a href="https://souleimane-z.github.io/zeggaisouleimane_4_20102021/" class="download-file" target="_blank" rel="noopener noreferrer">
Démo GitPages
          </a>
        </div>
      `,
    githubLink: "https://github.com/souleimane-z/zeggaisouleimane_4_20102021",
    techs: [
      "https://res.cloudinary.com/dhqh98spd/image/upload/v1718983533/excel-svgrepo-com_nkli94.svg",
      "https://res.cloudinary.com/dhqh98spd/image/upload/v1718881734/accessibility_gfhyjr.svg",
      "https://res.cloudinary.com/dhqh98spd/image/upload/v1718881732/seo_z7gahx.svg",
    ],
  },
  {
    id: "ohMyFood",
    title: "ohmyfood",
    slides: [
      {
        url: "https://res.cloudinary.com/dhqh98spd/image/upload/v1700125071/home-loading_knrsko.gif",
        title: "Home Loading",
      },
      {
        url: "https://res.cloudinary.com/dhqh98spd/image/upload/v1700125071/menu-animation_ctvvqu.gif",
        title: "Menu Animation",
      },
      {
        url: "https://res.cloudinary.com/dhqh98spd/image/upload/v1700125076/menu-layout_fjecwh.png",
        title: "Menu Layout",
      },
      {
        url: "https://res.cloudinary.com/dhqh98spd/image/upload/v1700125067/home_irhvgd.png",
        title: "Home",
      },
    ],
    content: `
        <p>Dans ce projet, je devais créer le site à partir de maquettes, les langages utilisés étaient <span class="bolder">HTML</span> et <span class="bolder">Sass</span> pour le design. Celui-ci devait être à <span class="italic-semiBold">100% Responsive</span> et des <span class="italic-semiBold">animations</span> devaient être reproduites à partir de vidéos et d'images.</p>
        <p>Ça comprenait :</p>
        <ul class="list">
          <li>Un loader quand on arrivait sur le site</li>
          <li>Des animations au hover de la souris sur les boutons</li>
          <li>Des icônes de confirmations lors du passage de la souris.</li>
        </ul>
        <div>
          <a href="https://souleimane-z.github.io/ZeggaiSouleimane_3_01092021/index.html" class="download-file " target="_blank" rel="noopener noreferrer">
Démo GitPages
          </a>
        </div>
      `,
    githubLink: "https://github.com/souleimane-z/ZeggaiSouleimane_3_01092021",
    techs: [
      "https://res.cloudinary.com/dhqh98spd/image/upload/v1718881736/html5_gvbsau.svg",
      "https://res.cloudinary.com/dhqh98spd/image/upload/v1718881736/scss_ywmkkn.svg",
      "https://res.cloudinary.com/dhqh98spd/image/upload/v1718881735/css3_k38wzt.svg",
    ],
  },
  {
    id: "Motiv",
    title: "cv",
    slides: [
      {
        url: "https://res.cloudinary.com/dhqh98spd/image/upload/v1700125122/about_gbncp0.png",
        title: "Home",
      },
      {
        url: "https://res.cloudinary.com/dhqh98spd/image/upload/v1700125118/VwBhLd_v9930w.png",
        title: "a propos",
      },
      {
        url: "https://res.cloudinary.com/dhqh98spd/image/upload/v1700125115/links_zce1qj.png",
        title: "links",
      },
    ],
    content: `
        <p>Ce site a été créé pour postuler à la formation de Développeur Web d'Openclassrooms. Entièrement codé en <span class="bolder">HTML</span> et <span class="bolder">CSS</span> pour le design.</p>
        <div>
          <a href="https://souleimane-z.github.io/Motiv_2021/" class="download-file " target="_blank" rel="noopener noreferrer">
Démo GitPages
          </a>
        </div>
      `,
    githubLink: "https://github.com/souleimane-z/Motiv_2021",
    techs: [
      "https://res.cloudinary.com/dhqh98spd/image/upload/v1718881736/html5_gvbsau.svg",
      "https://res.cloudinary.com/dhqh98spd/image/upload/v1718881731/javascript_sdrddu.svg",
      "https://res.cloudinary.com/dhqh98spd/image/upload/v1718881735/css3_k38wzt.svg",
    ],
  },
  {
    id: "Calculator",
    title: "Calculatrice",
    slides: [
      {
        url: "https://res.cloudinary.com/dhqh98spd/image/upload/v1700125153/calculator_wliqqi.jpg",
        title: "Calculator demo",
      },
    ],
    content: `
        <p>Capture d'écran 'side by side' pour exemple. Simple calculatrice codée en <span class="bolder">JavaScript</span> pour le calcul, <span class="bolder">HTML</span> et <span class="bolder">CSS</span> pour le design. Essayez-la grâce au lien en dessous</p>
        <div>
          <a href="https://souleimane-z.github.io/simplest_calculator_SZ/" class="download-file" target="_blank" rel="noopener noreferrer">
Démo GitPages
          </a>
        </div>
      `,
    githubLink: "https://github.com/souleimane-z/simplest_calculator_SZ",
    techs: [
      "https://res.cloudinary.com/dhqh98spd/image/upload/v1718881736/html5_gvbsau.svg",
      "https://res.cloudinary.com/dhqh98spd/image/upload/v1718881731/javascript_sdrddu.svg",
      "https://res.cloudinary.com/dhqh98spd/image/upload/v1718881735/css3_k38wzt.svg",
    ],
  },
  {
    id: "Clock",
    title: "Horloge",
    slides: [
      {
        url: "https://res.cloudinary.com/dhqh98spd/image/upload/v1700125165/clock_dhphys.png",
        title: "Clock demo",
      },
    ],
    content: `
        <p>Simple horloge codée en <span class="bolder">JavaScript</span> pour l'affichage de l'heure et la rotation des aiguilles, <span class="bolder">HTML</span> et <span class="bolder">CSS</span> pour le design. Essayez-la grâce au lien en dessous</p>
        <div>
          <a href="https://souleimane-z.github.io/clock-sz/" class="download-file" target="_blank" rel="noopener noreferrer">
Démo GitPages
          </a>
        </div>
      `,
    githubLink: "https://github.com/souleimane-z/clock-sz",
    techs: [
      "https://res.cloudinary.com/dhqh98spd/image/upload/v1718881736/html5_gvbsau.svg",
      "https://res.cloudinary.com/dhqh98spd/image/upload/v1718881731/javascript_sdrddu.svg",
      "https://res.cloudinary.com/dhqh98spd/image/upload/v1718881735/css3_k38wzt.svg",
    ],
  },
];

export default projects;
